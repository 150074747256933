<template>

  <div class="app">
    <!-- Preloader
    <div class="preloader">
      <div class="preloader-inner">
        <div class="preloader-icon">
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
     /End Preloader -->

    <!-- Start Header Area -->
    <header class="header navbar-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 container px-3">
            <div class="nav-inner">
              <!-- Start Navbar -->
              <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand pe-3" href="/application">
                  <img :src="require('@/assets/images/logos/logo.png')"/>
                </a>
                <a href="/auth/login"
                   style="color: white"
                   class="navbar-toggler mobile-menu-btn border-1 border-white p-2">
                  <v-icon style="color: white" size="30">{{ icons.mdiAccountPlusOutline }} </v-icon>
                </a>

                <a
                    href="/auth/login"
                    style="color: white"
                    class="navbar-toggler mobile-menu-btn border-1 border-white p-2"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom"
                    aria-expanded="false" aria-label="Toggle navigation">
                  <v-icon style="color: white" size="30">{{ icons.mdiAccountCircle }} </v-icon>
                </a>
                <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">

                </div> <!-- navbar collapse -->
                <div class="button add-list-button">
                  <select v-model="language" @change="changeLanguage">
                    <option value="fr">
                      FR
                    </option>
                    <option value="en">
                      EN
                    </option>
                  </select>
                </div>
                <div class="button add-list-button">
                  <a href="/auth/register" class="btn"><v-icon style="color: white" size="20">{{ icons.mdiAccountPlusOutline }} </v-icon>  {{$t('app.create_account')}}</a>
                </div>
                <div class="button add-list-button">
                  <a href="/auth/login" class="btn"><v-icon style="color: white" size="20">{{ icons.mdiAccountCircle }} </v-icon> {{$t('app.log_account')}}</a>
                </div>
              </nav>
              <!-- End Navbar -->
            </div>
          </div>
        </div> <!-- row -->
      </div> <!-- container -->
    </header>
    <!-- End Header Area -->

    <!-- Start Hero Area -->
    <section id="home" class="hero-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12 col-12">
            <div class="hero-content">
              <h1 class="wow fadeInLeft" data-wow-delay=".4s">{{$t("app.home_title")}} </h1>
              <p class="wow fadeInLeft" data-wow-delay=".6s">{{$t("app.home_subtitle")}}</p>
              <div class="button wow fadeInLeft w-100" data-wow-delay=".8s">
                <a href="javascript:void(0)" style="
    width: 260px !important;
" class="btn"><v-icon style="color: #104f94" size="40">{{ icons.mdiApple }}</v-icon> App Store</a>
                <a href="javascript:void(0)" size="40" class="btn" style="
    width: 260px !important;
"><v-icon style="color: #104f94" size="40">{{ icons.mdiGooglePlay }}</v-icon> Google Play</a>
              </div>
              <div class="button wow fadeInLeft mt-2" data-wow-delay=".8s">
                <a href="/sendcolis" class="btn w-100 btn-alt">
                  <v-icon style="color: white" size="40">{{ icons.mdiCubeSend }}</v-icon>
                  {{ $t("app.home_package") }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <div class="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img :src="require('@/assets/images/pro/app1.png')" alt="#">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero Area -->

    <!-- Start App Info -->
    <section id="overview" class="app-info section">
      <div class="container">
        <div class="info-one">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12">
              <div class="info-text wow fadeInLeft" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                <div class="main-icon">
                  <i class="lni lni-inbox"></i>
                </div>
                <h2>{{$t("app.feature.first_block.title")}}</h2>
                <p>{{$t("app.feature.first_block.subtitle")}}</p>
                <div class="button">
                  <a href="javascript:void(0)" class="btn">{{$t("app.feature.first_block.btn_msg")}}</a>
                </div>
              </div>

            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="info-image wow fadeInRight" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInRight;">
                <img class="ss1" :src="require('@/assets/images/pro/features.png')" alt="#" />
              </div>
            </div>
          </div>
        </div>
        <div class="info-one style2">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12">
              <div class="info-image wow fadeInLeft" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                <img class="ss1" :src="require('@/assets/images/pro/features1.png')" alt="#">
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">

              <div class="info-text wow fadeInRight" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInRight;">
                <div class="main-icon">
                  <i class="lni lni-layout"></i>
                </div>
                <h2>{{$t("app.feature.second_block.title")}}</h2>
                <p>{{$t("app.feature.second_block.subtitle")}}</p>
                <div class="button">
                  <a href="javascript:void(0)" class="btn">{{$t("app.feature.second_block.btn_msg")}}</a>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="info-one">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12">

              <div class="info-text wow fadeInLeft" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                <div class="main-icon">
                  <i class="lni lni-inbox"></i>
                </div>
                <h2>{{$t("app.feature.third_block.title")}}</h2>
                <p>{{$t("app.feature.third_block.subtitle")}}</p>
                <div class="button">
                  <a href="javascript:void(0)" class="btn">{{$t("app.feature.second_block.btn_msg")}}</a>
                </div>
              </div>

            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="info-image wow fadeInRight" data-wow-delay=".5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInRight;">
                <img class="ss1" :src="require('@/assets/images/pro/features2.png')" alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start Call To Action Area -->
    <section class="section call-action">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 col-12">
                <div class="info-image wow fadeInLeft" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                  <img class="ss1" :src="require('@/assets/images/pro/slack.png')" alt="#">
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-12">

                <div class="cta-content" style="text-align: left; padding: 0px !important;">
                  <h1 class="wow fadeInUp main-title fw-bold" style="
    font-size: 50px !important;
    font-weight: 800;
    line-height: 70px !important;
    color: #fff;
    text-shadow: 0px 3px 8px #00000017;
    text-transform: capitalize;
">{{$t("app.call_action.title")}}
                  </h1>
                  <p style="
    font-size: 20px !important;
    line-height: 30px;
" class="fadeInUp" data-wow-delay=".4s">{{$t("app.call_action.subtitle")}}</p>
                  <div class="button wow fadeInUp" data-wow-delay=".6s">
                    <a href="javascript:void(0)" class="btn">{{$t('app.call_action.btn_msg')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action Area -->

    <!-- Start Footer Area -->
    <footer class="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12">

              <div class="single-footer f-about">
                <div class="logo">
                  <a href="#">
                    <img :src="require('@/assets/images/logos/logo.png')" alt="#">
                  </a>
                </div>
                <p>Plus qu'un outil de logistique, un accompagnement stratégique.</p>
                <ul class="social">
                  <li><a href="javascript:void(0)"><i class="lni lni-facebook-filled"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="lni lni-twitter-original"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="lni lni-instagram"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="lni lni-linkedin-original"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="lni lni-youtube"></i></a></li>
                  <li><a href="javascript:void(0)"><i class="lni lni-pinterest"></i></a></li>
                </ul>
                <p class="copyright-text">Designed and Developed by <a href="https://business.spacekola.com/" rel="nofollow" target="_blank">spacekola</a>
                </p>
              </div>

            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">

                  <div class="single-footer f-link">
                    <h3>Informations</h3>
                    <ul>
                      <li><a href="javascript:void(0)">Politique de confidentialité</a></li>
                      <li><a href="javascript:void(0)">A propos de nous</a></li>
                      <li><a href="javascript:void(0)">Délai de livraison</a></li>
                      <li><a href="javascript:void(0)">Option de paiement</a></li>
                    </ul>
                  </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                  <div class="single-footer f-link">
                    <h3>Détail du contact</h3>
                    <ul>
                      <li><a href="javascript:void(0)">Bonamoussadi Rond-Point Maetur</a></li>
                      <li><a href="javascript:void(0)">(237) 696 475 981</a></li>
                      <li><a href="javascript:void(0)"> (237) 696 475 981</a></li>
                      <li><a href="javascript:void(0)">Envoyez nous une message via la page Nous contacter</a></li>
                    </ul>
                  </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                  <div class="single-footer f-link">
                    <h3>FAQ</h3>
                    <ul>
                      <li><a href="javascript:void(0)">Politique de retour et de remboursement</a></li>
                      <li><a href="javascript:void(0)">Mon compte </a></li>
                      <li><a href="javascript:void(0)">Paiement</a></li>
                      <li><a href="javascript:void(0)">Status de course</a></li>
                    </ul>
                  </div>

                </div>
                <div class="col-lg-3 col-md-6 col-12">

                  <div class="single-footer f-link">
                    <h3>Mon compte</h3>
                    <ul>
                      <li><a href="javascript:void(0)">Se connecter</a></li>
                      <li><a href="javascript:void(0)">S'inscrire</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="footer-newsletter">
        <div class="container">
          <div class="inner-content">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-5 col-12">
                <div class="title">
                  <h3>Abonnez-vous à notre newsletter</h3>
                  <p>Les dernières nouvelles, articles et ressources, envoyés chaque semaine dans votre boîte de réception.</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-7 col-12">
                <div class="form">
                  <form action="#" method="get" target="_blank" class="newsletter-form">
                    <input name="EMAIL" placeholder="Votre adresse e-mail" type="email">
                    <div class="button">
                      <button class="btn">S'abonner<span class="dir-part"></span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
    <!--/ End Footer Area -->

    <!-- ========================= scroll-top ========================= -->
    <a href="#" class="scroll-top">
      <i class="lni lni-chevron-up"></i>
    </a>
  </div>

</template>

<script>
import i18n from "@/i18n";
import {mdiAccountPlusOutline, mdiAccountCircle, mdiGooglePlay, mdiApple, mdiCubeSend} from '@mdi/js'
export default {
  name: "Application",
  data: () => ({
    icons: { mdiAccountPlusOutline, mdiAccountCircle, mdiGooglePlay, mdiApple, mdiCubeSend},
    language: localStorage.getItem('lang')
  }),
  methods: {
    changeLanguage(){
      localStorage.setItem('lang', this.language);
      window.location.reload();
    }
  },
  created() {
    if(localStorage.getItem('lang') == null){
      localStorage.setItem('lang', i18n.locale);
    }
  }
}
</script>

<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import '../../assets/css/main.css';
.call-action .cta-content p {
  color: #fff;
  padding: 0px 0px !important;
}
.hero-area .hero-content h1 {
  font-size: 50px !important;
  font-weight: 800;
  line-height: 70px !important;
  color: #fff;
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
}

 h1 {
   font-size: 50px !important;
 }

h2 {
  font-size: 40px !important;
}

 h1, h2, h3, h4, h5, h6 {
   font-weight: 600;
   margin: 0px;
   color: #081828;
 }

@media (max-width: 767px){

  .header .navbar-brand img {
    width: 130px !important;
  }
   .hero-area .hero-content p {
     margin-top: 15px;
     font-size: 14px;
     font-size: 14px;
     line-height: 22px;
     text-align: justify;
   }

  .hero-area .hero-content h1{
    font-size: 23px !important;
    font-weight: 800;
    line-height: 28px !important;
    color: #fff;
    text-shadow: 0px 3px 8px #00000017;
    text-transform: capitalize;
    margin-top: 20px;
  }
 }

@media (min-width: 768px){
  .header .navbar-brand img {
    width: 400px !important;
  }
}

</style>